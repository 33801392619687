import "@pnotify/core/dist/PNotify.css";
import "@pnotify/mobile/dist/PNotifyMobile.css";
import "@pnotify/core/dist/BrightTheme.css";
import "swiper/swiper-bundle.min.css";
import "basiclightbox/dist/basicLightbox.min.css";
import "./sass/main.scss";
import "./js/swiper-slider/swipers";
import "./js/refs/refs";
import "./js/menu/menu";
import "./js/menu/scrollInto";
import "./js/basiclightbox/basiclightbox";
import "./js/counter/counter-plus";
import "./js/form/form";
import "./js/form/remove-class-submit-form";
import "./js/common/scroll-into-start";
import "./js/common/off-link";
import "./js/btn-orders/btn-orders";
import "./js/btn-up/btn-up";
import "./js/form/on-submit-telegram";
import "./js/copy-year/copy-year";
